import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { initTheme } from '@/core/services/theme';
import App from '@/App.vue';
import vClickOutside from 'click-outside-vue3';
import Confirm from '@/core/plugins/confirm';
import Notification from '@/core/plugins/notification/index';
import router from '@/router';
import store from '@/store';

import 'simplebar';
import 'simplebar/dist/simplebar.css';

export function createDefaultApp() {
  const i18n = createI18n({
    legacy: false,
    locale: 'ru',
    messages: {},
  });

  initTheme();

  return createApp(App)
    .use(vClickOutside)
    .use(Confirm)
    .use(Notification)
    .use(store)
    .use(router)
    .use(i18n);
}

export default createDefaultApp();
