const arrayToRBG = (...args) => `rgb(${args.join(',')})`;
const rbgToArray = (rgbColor) => rgbColor.match(/\d+/g);

const blendColors = (...args) => {
  let base = [0, 0, 0, 0];
  let mix;
  let added;
  while ((added = args.shift())) {
    if (typeof added[3] === 'undefined') {
      added[3] = 1;
    }
    // check if both alpha channels exist.
    if (base[3] && added[3]) {
      mix = [0, 0, 0, 0];
      // alpha
      mix[3] = 1 - (1 - added[3]) * (1 - base[3]);
      // red
      mix[0] = Math.round(
        (added[0] * added[3]) / mix[3] + (base[0] * base[3] * (1 - added[3])) / mix[3],
      );
      // green
      mix[1] = Math.round(
        (added[1] * added[3]) / mix[3] + (base[1] * base[3] * (1 - added[3])) / mix[3],
      );
      // blue
      mix[2] = Math.round(
        (added[2] * added[3]) / mix[3] + (base[2] * base[3] * (1 - added[3])) / mix[3],
      );
    } else if (added) {
      mix = added;
    } else {
      mix = base;
    }
    base = mix;
  }

  return mix;
};

const addAlphaChannel = (rgb, opacity) => [...rgb, opacity];

const createToneColor = (baseColor, toneColor, alpha) => {
  const rgbaColorArray = blendColors(
    addAlphaChannel(rbgToArray(baseColor), 1),
    addAlphaChannel(rbgToArray(toneColor), alpha),
  );

  return `rgb(${rgbaColorArray.splice(0, 3).join(',')})`;
};

const BASE_COLORS = {
  WHITE: arrayToRBG([255, 255, 255]),
  BLACK: arrayToRBG([22, 30, 47]),
  MAIN: arrayToRBG([91, 58, 239]),
  MAIN_ALT: arrayToRBG([160, 90, 255]),
  ACCENT: arrayToRBG([255, 32, 105]),
  ACCENT_ALT: arrayToRBG([252, 77, 155]),
  BASE_WHITE: arrayToRBG([255, 255, 255]),
  ADD_AQUAMARINE: arrayToRBG([37, 218, 157]),
  ADD_YELLOW: arrayToRBG([255, 210, 0]),
  SERVICE_ALARM: arrayToRBG([255, 31, 0]),
  SERVICE_WARNING: arrayToRBG([255, 155, 34]),
  SERVICE_SUCCESS: arrayToRBG([62, 217, 36]),
  SERVICE_INFO_BLUE: arrayToRBG([39, 126, 255]),
  SERVICE_INFO_BLACK: arrayToRBG([22, 30, 47]),
};

/*eslint-disable */
const THEME_COLORS = {
  ROOT_MAIN: BASE_COLORS.MAIN,
  ROOT_MAIN_ALT: BASE_COLORS.MAIN_ALT,
  ROOT_ACCENT: BASE_COLORS.ACCENT,
  ROOT_ACCENT_ALT: BASE_COLORS.ACCENT_ALT,
  ROOT_BASE_WHITE: BASE_COLORS.BASE_WHITE,
  ROOT_ADD_AQUAMARINE: BASE_COLORS.ADD_AQUAMARINE,
  ROOT_ADD_YELLOW: BASE_COLORS.ADD_YELLOW,
  ROOT_SERVICE_ALARM: BASE_COLORS.SERVICE_ALARM,
  ROOT_SERVICE_WARNING: BASE_COLORS.SERVICE_WARNING,
  ROOT_SERVICE_SUCCESS: BASE_COLORS.SERVICE_SUCCESS,
  ROOT_SERVICE_INFO_BLUE: BASE_COLORS.SERVICE_INFO_BLUE,
  ROOT_SERVICE_INFO_BLACK: BASE_COLORS.SERVICE_INFO_BLACK,

  ROOT_MAIN_HOVER: createToneColor(BASE_COLORS.MAIN, BASE_COLORS.BLACK, 0.1),
  ROOT_MAIN_ALT_HOVER: createToneColor(BASE_COLORS.MAIN_ALT, BASE_COLORS.BLACK, 0.1),
  ROOT_ACCENT_HOVER: createToneColor(BASE_COLORS.ACCENT, BASE_COLORS.BLACK, 0.1),
  ROOT_ACCENT_ALT_HOVER: createToneColor(BASE_COLORS.ACCENT_ALT, BASE_COLORS.BLACK, 0.1),
  ROOT_BLACK5: createToneColor(BASE_COLORS.BLACK, BASE_COLORS.WHITE, 0.95),
  ROOT_BLACK10: createToneColor(BASE_COLORS.BLACK, BASE_COLORS.WHITE, 0.9),

  LIGHT_MAIN_HOVER: createToneColor(BASE_COLORS.MAIN, BASE_COLORS.WHITE, 0.85),
  LIGHT_ACCENT_HOVER: createToneColor(BASE_COLORS.ACCENT, BASE_COLORS.BLACK, 0.85),
  LIGHT_SERVICE_ALARM_HOVER: createToneColor(BASE_COLORS.SERVICE_ALARM, BASE_COLORS.BLACK, 0.85),
  LIGHT_SERVICE_WARNING_HOVER: createToneColor(BASE_COLORS.SERVICE_WARNING, BASE_COLORS.BLACK, 0.85),
  LIGHT_SERVICE_SUCCESS_HOVER: createToneColor(BASE_COLORS.SERVICE_SUCCESS, BASE_COLORS.BLACK, 0.85),
  LIGHT_SERVICE_INFO_BLUE_HOVER: createToneColor(BASE_COLORS.SERVICE_INFO_BLUE, BASE_COLORS.BLACK, 0.85),
  LIGHT_SERVICE_INFO_BLACK_HOVER: createToneColor(BASE_COLORS.SERVICE_INFO_BLACK, BASE_COLORS.BLACK, 0.85),

  LIGHT_MAIN: createToneColor(BASE_COLORS.MAIN, BASE_COLORS.WHITE, 0.9),
  LIGHT_ACCENT: createToneColor(BASE_COLORS.ACCENT, BASE_COLORS.BLACK, 0.9),
  LIGHT_SERVICE_ALARM: createToneColor(BASE_COLORS.SERVICE_ALARM, BASE_COLORS.BLACK, 0.9),
  LIGHT_SERVICE_WARNING: createToneColor(BASE_COLORS.SERVICE_WARNING, BASE_COLORS.WHITE, 0.9),
  LIGHT_SERVICE_SUCCESS: createToneColor(BASE_COLORS.SERVICE_SUCCESS, BASE_COLORS.BLACK, 0.9),
  LIGHT_SERVICE_INFO_BLUE: createToneColor(BASE_COLORS.SERVICE_INFO_BLUE, BASE_COLORS.WHITE, 0.9),
  LIGHT_SERVICE_INFO_BLACK: createToneColor(BASE_COLORS.SERVICE_INFO_BLACK, BASE_COLORS.BLACK, 0.9),

  TEXT_MAIN: BASE_COLORS.MAIN,
  TEXT_MAIN_ALT: BASE_COLORS.MAIN_ALT,
  TEXT_ACCENT: BASE_COLORS.ACCENT,
  TEXT_ACCENT_ALT: BASE_COLORS.ACCENT_ALT,
  TEXT_BLACK: BASE_COLORS.BLACK,
  TEXT_BASE_WHITE: BASE_COLORS.WHITE,
  TEXT_ADD_AQUAMARINE: BASE_COLORS.ADD_AQUAMARINE,
  TEXT_ADD_YELLOW: BASE_COLORS.ADD_YELLOW,
  TEXT_SERVICE_ALARM: BASE_COLORS.SERVICE_ALARM,
  TEXT_SERVICE_WARNING: BASE_COLORS.SERVICE_WARNING,
  TEXT_SERVICE_SUCCESS: BASE_COLORS.SERVICE_SUCCESS,
  TEXT_SERVICE_INFO_BLUE: BASE_COLORS.SERVICE_INFO_BLUE,
  TEXT_BLACK80: createToneColor(BASE_COLORS.BLACK, BASE_COLORS.WHITE, 0.2),
  TEXT_BLACK55: createToneColor(BASE_COLORS.BLACK, BASE_COLORS.WHITE, 0.45),
  TEXT_BLACK20: createToneColor(BASE_COLORS.BLACK, BASE_COLORS.WHITE, 0.8),

  ICON_MAIN: BASE_COLORS.MAIN,
  ICON_MAIN_ALT: BASE_COLORS.MAIN_ALT,
  ICON_ACCENT: BASE_COLORS.ACCENT,
  ICON_ACCENT_ALT: BASE_COLORS.ACCENT_ALT,
  ICON_BLACK: BASE_COLORS.BLACK,
  ICON_BASE_WHITE: BASE_COLORS.WHITE,
  ICON_ADD_AQUAMARINE: BASE_COLORS.ADD_AQUAMARINE,
  ICON_ADD_YELLOW: BASE_COLORS.ADD_YELLOW,
  ICON_SERVICE_ALARM: BASE_COLORS.SERVICE_ALARM,
  ICON_SERVICE_WARNING: BASE_COLORS.SERVICE_WARNING,
  ICON_SERVICE_SUCCESS: BASE_COLORS.SERVICE_SUCCESS,
  ICON_SERVICE_INFO_BLUE: BASE_COLORS.SERVICE_INFO_BLUE,
  ICON_BLACK20: createToneColor(BASE_COLORS.BLACK, BASE_COLORS.WHITE, 0.8),

  LINE_MAIN: BASE_COLORS.MAIN,
  LINE_MAIN_ALT: BASE_COLORS.MAIN_ALT,
  LINE_ACCENT: BASE_COLORS.ACCENT,
  LINE_ACCENT_ALT: BASE_COLORS.ACCENT_ALT,
  LINE_BLACK10: createToneColor(BASE_COLORS.BLACK, BASE_COLORS.WHITE, 0.9),
  LINE_ADD_AQUAMARINE: BASE_COLORS.ADD_AQUAMARINE,
  LINE_ADD_YELLOW: BASE_COLORS.ADD_YELLOW,
  LINE_SERVICE_ALARM: BASE_COLORS.SERVICE_ALARM,
  LINE_SERVICE_WARNING: BASE_COLORS.SERVICE_WARNING,
  LINE_SERVICE_SUCCESS: BASE_COLORS.SERVICE_SUCCESS,
  LINE_SERVICE_INFO_BLUE: BASE_COLORS.SERVICE_INFO_BLUE,
};
/* eslint-enable */

module.exports = {
  THEME_COLORS,
  rbgToArray,
};
