import Axios from 'axios';
import config from '@/config';
import Store from '@/store';
import { setError } from '@/core/services/error';

export async function call(method, url, payload = {}, contentType = 'application/json') {
  const headers = {
    'Content-Type': contentType,
    'X-Language': 'ru',
    'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const { token } = Store.state.user;
  if (token) {
    headers['X-Auth-Token'] = token;
  }

  const request = {
    url,
    method,
    headers,
    baseURL: config.urls.api,
  };

  if (['GET', 'DELETE'].includes(method)) {
    request.params = payload;
  } else {
    request.data = payload;
  }

  const response = await Axios(request);

  if (response.data) {
    const { data } = response;
    if (data.code === 409) {
      setError(data.message, data.error_code, data.errors);
    }

    return data;
  }

  return null;
}

export default {
  call,
};
