export default {
  namespaced: true,

  state: {
    authMessage: '',
    errorCode: null,
    errors: [],
    backRoute: null,
  },

  mutations: {
    authMessage(state, value) {
      state.authMessage = value;
    },

    errorCode(state, value) {
      state.errorCode = value;
    },

    errors(state, value) {
      state.errors = value;
    },

    backRoute(state, value) {
      state.backRoute = value;
    },
  },
};
