<template>
  <div class="ip-notification">
    <div
      v-for="item in items"
      :class="getClass(item)"
      :key="item.index"
    >
      <IconSuccess v-if="item.type === 'success'"/>
      <IconError v-if="item.type === 'error'"/>

      <div class="ip-notification__message">
        <div class="ip-notification__title">
          {{ item.title }}
        </div>
        <div class="ip-notification__description">
          {{ item.description }}
        </div>
      </div>

      <div
        class="ip-notification__close"
        @click="remove(item.index)"
        @keyup="remove(item.index)"
      >
        <IPIcon name="close" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import IconSuccess from '@/core/plugins/notification/icons/IconSuccess.vue';
import IconError from '@/core/plugins/notification/icons/IconError.vue';
import IPIcon from '@/core/components/ui/IPIcon';

const items = ref({});

export function remove(index) {
  delete items.value[index];
}

export function add(title, description, type = 'info', options = {}) {
  const duration = options.duration || 3000;

  const date = new Date();
  const index = date.getTime() + date.getMilliseconds();

  items.value[index] = {
    index,
    title,
    description,
    type,
    options,
  };

  setTimeout(() => remove(index), duration);
}

export default {
  name: 'IPNotifications',

  components: {
    IconSuccess,
    IconError,
    IPIcon,
  },

  setup() {
    const { t } = useI18n();

    function getClass(item) {
      return ['ip-notification__item', `ip-notification__item_${item.type}`, 'ip-shadow-m'];
    }

    return {
      t,
      items,
      getClass,
      add,
      remove,
    };
  },
};
</script>

<style lang="scss">
.ip-notification {
  position: fixed;
  left: 24px;
  bottom: 0;
  width: 300px;
  z-index: 900000;

  &__item {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
  }

  &__message {
    flex: 1;
    margin-left: 16px;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__close {
    svg {
      cursor: pointer;
    }
  }
}
</style>
