<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_1845_9422" fill="white">
      <path
        d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15
        0C23.2843 0 30 6.71573 30 15Z"
      />
    </mask>

    <path
      d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15
      0C23.2843 0 30 6.71573 30 15Z"
      fill="#FC6E7A"
    />
    <path
      d="M29 15C29 22.732 22.732 29 15 29V31C23.8366 31 31 23.8366 31 15H29ZM15 29C7.26801 29 1
      22.732 1 15H-1C-1 23.8366 6.16344 31 15 31V29ZM1 15C1 7.26801 7.26801 1 15 1V-1C6.16344 -1 -1
      6.16344 -1 15H1ZM15 1C22.732 1 29 7.26801 29 15H31C31 6.16344 23.8366 -1 15 -1V1Z"
      fill="#FC6E7A"
      mask="url(#path-1-inside-1_1845_9422)"
    />
    <path
      d="M10.0913 9.24224C9.85694 9.00793 9.47704 9.00793 9.24273 9.24224C9.00841 9.47655 9.00841
      9.85645 9.24273 10.0908L10.0913 9.24224ZM19.9094 20.7574C20.1437 20.9917 20.5236 20.9917
      20.7579 20.7574C20.9922 20.5231 20.9922 20.1432 20.7579 19.9089L19.9094 20.7574ZM20.7579
      10.0908C20.9922 9.85645 20.9922 9.47655 20.7579 9.24224C20.5236 9.00793 20.1437 9.00793
      19.9094 9.24224L20.7579 10.0908ZM9.24273 19.9089C9.00841 20.1432 9.00841 20.5231 9.24273
      20.7574C9.47704 20.9917 9.85694 20.9917 10.0913 20.7574L9.24273 19.9089ZM9.24273
      10.0908L14.5761 15.4241L15.4246 14.5756L10.0913 9.24224L9.24273 10.0908ZM14.5761
      15.4241L19.9094 20.7574L20.7579 19.9089L15.4246 14.5756L14.5761 15.4241ZM15.4246
      15.4241L20.7579 10.0908L19.9094 9.24224L14.5761 14.5756L15.4246 15.4241ZM14.5761
      14.5756L9.24273 19.9089L10.0913 20.7574L15.4246 15.4241L14.5761 14.5756Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconError',
};
</script>
