<template>
  <IPModal v-model="isShow">
    <template v-slot:title>
      {{ t(`error_code_${errorCode}_title`) }}
    </template>
    {{ t(`error_code_${errorCode}_message`) }}

    <div v-for="error in errors" :key="error.id">
      <div>{{ error.name }}</div>
    </div>

    <template v-slot:footer>
      <IPButton @click="handleAction" adaptive>
        {{ t(`error_code_${errorCode}_action`) }}
      </IPButton>
    </template>
  </IPModal>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { emailConfirmResend } from '@/core/requests/auth';

import IPModal from '@/core/components/ui/IPModal';
import IPButton from '@/core/components/ui/IPButton';

export default {
  name: 'ErrorModal',

  components: {
    IPModal,
    IPButton,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isShow = computed({
      get() {
        return store.state.application.errorCode !== null;
      },

      set(value) {
        if (value === false) {
          store.commit('application/errorCode', null);
        }
      },
    });

    const errorCode = computed(() => store.state.application.errorCode);
    const errors = computed(() => store.state.application.errors);

    const handleAction = async () => {
      switch (errorCode.value) {
        case 1001:
          await router.push({ name: route.name, query: { m: 'login' } });
          break;
        case 1002:
        case 2003:
        case 2004:
          await router.push({ name: 'profile' });
          break;
        case 1003:
          await router.push({ name: 'studies' });
          break;
        case 3002: {
          const response = await emailConfirmResend();
          if (response.code === 200) {
            await router.push({ name: route.name, query: {} });
          }
          break;
        }
        case 1:
          await router.push({ name: 'profile' });
          break;
        default:
      }

      isShow.value = false;
    };

    return {
      t,
      handleAction,
      isShow,
      message: computed(() => store.state.application.authMessage),
      errorCode,
      errors,
    };
  },
};
</script>

<i18n>
{
  "ru": {
    "error_code_1001_title": "Войдите в аккаунт",
    "error_code_1001_message": "Раздел будет доступен после входа в аккаунт",
    "error_code_1001_action": "Перейти ко входу",

    "error_code_1002_title": "Заполните профиль",
    "error_code_1002_message": "Раздел будет доступен после заполнения профиля",
    "error_code_1002_action": "Перейти в профиль",

    "error_code_1003_title": "Доступ запрещен",
    "error_code_1003_message": "Доступ в раздел запрещен",
    "error_code_1003_action": "На главную",

    "error_code_1004_title": "Ваш отклик отправлен!",
    "error_code_1004_message": "Ответ придет вам на электронную почту, которую вы указали при регистрации",
    "error_code_1004_action": "Ок",

    "error_code_1005_title": "Для удаления компании свяжитесь с нами  по адресу info{'@'}clinmarket.ru",
    "error_code_1005_message": "",
    "error_code_1005_action": "Ок",

    "error_code_1006_title": "Регистрация прошла успешно!",
    "error_code_1006_message": "Полный функционал будет доступен после подтверждения",
    "error_code_1006_action": "Ок",

    "error_code_1007_title": "Отправлено",
    "error_code_1007_message": "Письмо для восстановления пароля отправлено на почту",
    "error_code_1007_action": "Ок",

    "error_code_1008_title": "Такого исследователя в базе ГРЛС на найдено. Проверьте введенные данные",
    "error_code_1008_message": "",
    "error_code_1008_action": "Ок",

    "error_code_2001_title": "Ваш профиль находится на проверке",
    "error_code_2001_message": "Раздел будет доступен после проверки вашего профиля",
    "error_code_2001_action": "Ок",

    "error_code_2002_title": "Ваш профиль находится на проверке",
    "error_code_2002_message": "Раздел будет доступен после проверки вашего профиля",
    "error_code_2002_action": "Ок",

    "error_code_2003_title": "Пожалуйста, отправьте ваш профиль на проверку",
    "error_code_2003_message": "Раздел будет доступен после проверки вашего профиля",
    "error_code_2003_action": "В профиль",

    "error_code_2004_title": "Пожалуйста, подтвердите email",
    "error_code_2004_message": "Раздел будет доступен после подтверждения email",
    "error_code_2004_action": "В профиль",

    "error_code_3001_title": "Ваш email подтвержден",
    "error_code_3001_message": "",
    "error_code_3001_action": "Ок",

    "error_code_3002_title": "Код активации устарел",
    "error_code_3002_message": "",
    "error_code_3002_action": "Отправить новый",

    "error_code_3003_title": "Код активации отправлен на почту",
    "error_code_3003_message": "",
    "error_code_3003_action": "Ок",

    "error_code_4001_title": "Приглашение устарело или было удалено",
    "error_code_4001_message": "Пожалуйста, обратитесь к своему администратору.",
    "error_code_4001_action": "Ок",

    "error_code_0_title": "Вы уже состоите в этой компании",
    "error_code_0_message": "Пожалуйста выберите другую компанию",
    "error_code_0_action": "Закрыть",

    "error_code_1_title": "Заполните профиль",
    "error_code_1_message": "Раздел будет доступен после заполнения профиля",
    "error_code_1_action": "Перейти в профиль",

    "error_code_18_title": "Главный исследователь уже зарегистрирован",
    "error_code_18_message": "",
    "error_code_18_action": "ОК",

    "error_code_19_title": "Вы уже откликнулись на данное объявление",
    "error_code_19_message": "",
    "error_code_19_action": "ОК",

    "error_code_24_title": "Пользователь уже состоит в компании",
    "error_code_24_message": "",
    "error_code_24_action": "ОК",

    "error_code_27_title": "Вы уже отправили приглашение",
    "error_code_27_message": "Пожалуйста, выберите другое объявление",
    "error_code_27_action": "ОК",

    "error_code_29_title": "Вы не можете пригласить данного пользователя, так как он является главным исследователем",
    "error_code_29_message": "",
    "error_code_29_action": "ОК",

    "error_code_30_title": "Главный исследователь не может принять приглашение",
    "error_code_30_message": "",
    "error_code_30_action": "ОК",

    "error_code_31_title": "Вы не можете удалить опрос, так как он привязан к вакансиям",
    "error_code_31_message": "Что бы удалить опрос, пожалуйста, поменяйте опросы в следующих вакансиях",
    "error_code_31_action": "ОК",

    "error_code_14_title": "Исполнитель не предоставляет услуг, необходимые в данном объявлении",
    "error_code_14_message": "Пожалуйста, выберете другое объявление",
    "error_code_14_action": "ОК",

    "error_code_32_title": "Исполнитель не предоставляет услуг, необходимые в данном объявлении",
    "error_code_32_message": "Пожалуйста, выберете другое объявление",
    "error_code_32_action": "ОК"
  }
}
</i18n>
