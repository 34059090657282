<template>
  <mask
    id="mask0_729_11057"
    style="mask-type:alpha"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="24"
    height="24"
  >
    <path
      d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3
        13.866 6.13401 17 10 17Z"
      stroke="#161E2F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 21L15 15"
      stroke="#161E2F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </mask>
  <g mask="url(#mask0_729_11057)">
    <rect width="24" height="24" fill="#161E2F"/>
    <rect width="24" height="24" fill="white" fill-opacity="0.8"/>
  </g>
</template>

<script>
export default {
  name: 'SearchIcon',
};
</script>
