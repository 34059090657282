<template>
  <circle
    cx="12"
    cy="12"
    r="2"
    stroke="#161E2F"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <circle
    cx="12"
    cy="12"
    r="2"
    stroke="white"
    stroke-opacity="0.8"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"/>
  <path
    d="M22 12C19.333 16.667 16 19 12 19C8 19 4.667 16.667 2 12C4.667 7.333 8 5 12 5C16 5 19.333
    7.333 22 12"
    stroke="#161E2F"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M22 12C19.333 16.667 16 19 12 19C8 19 4.667 16.667 2 12C4.667 7.333 8 5 12 5C16 5 19.333
    7.333 22 12"
    stroke="white"
    stroke-opacity="0.8"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>

<script>
export default {
  name: 'VisibilityIcon',
};
</script>
