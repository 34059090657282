<template>
  <path
    d="M3 3L21 21"
    stroke="#161E2F"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M3 3L21 21"
    stroke="white"
    stroke-opacity="0.8"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10.584 10.5869C9.80248 11.3678 9.80203
    12.6344 10.583 13.4159C11.3639 14.1974 12.6305 14.1978 13.412 13.4169"
    stroke="#161E2F"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10.584 10.5869C9.80248 11.3678 9.80203 12.6344 10.583 13.4159C11.3639 14.1974 12.6305
    14.1978 13.412 13.4169"
    stroke="white"
    stroke-opacity="0.8"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M9.08797 4.40368C8.55698 4.55558 8.24967 5.10916 8.40157 5.64015C8.55346 6.17114 9.10705
    6.47845 9.63803 6.32655L9.08797 4.40368ZM12 5.00012L11.9965 6.00012H12V5.00012ZM22
    12.0001L22.8682 12.4964C23.0439 12.1889 23.0439 11.8114 22.8682 11.504L22 12.0001ZM18.7626
    14.8094C18.3878 15.2149 18.4127 15.8476 18.8182 16.2225C19.2238 16.5973 19.8565 16.5724 20.2314
    16.1669L18.7626 14.8094ZM17.9162 18.1782C18.374 17.8694 18.4949 17.2478 18.1861 16.79C17.8773
    16.3321 17.2557 16.2112 16.7979 16.52L17.9162 18.1782ZM2 12.0001L1.13182 11.5039C0.956079
    11.8113 0.956057 12.1888 1.13177 12.4963L2 12.0001ZM7.19335 7.4867C7.65041 7.17667 7.7696
    6.55483 7.45958 6.09777C7.14956 5.64071 6.52771 5.52151 6.07065 5.83154L7.19335 7.4867ZM9.63803
    6.32655C10.4049 6.10719 11.1989 5.99728 11.9965 6.00011L12.0035 4.00012C11.0175 3.99663 10.036
    4.13249 9.08797 4.40368L9.63803 6.32655ZM12 6.00012C15.52 6.00012 18.5732 8.01902 21.1318
    12.4963L22.8682 11.504C20.0928 6.64721 16.48 4.00012 12 4.00012V6.00012ZM21.1318 11.5038C20.3842
    12.8117 19.5933 13.9106 18.7626 14.8094L20.2314 16.1669C21.1827 15.1376 22.0598 13.9105 22.8682
    12.4964L21.1318 11.5038ZM16.7979 16.52C15.3292 17.5105 13.7394 18.0001 12
    18.0001V20.0001C14.1446 20.0001 16.1228 19.3877 17.9162 18.1782L16.7979 16.52ZM12
    18.0001C8.48001 18.0001 5.4268 15.9812 2.86823 11.504L1.13177 12.4963C3.9072 17.353 7.51999
    20.0001 12 20.0001V18.0001ZM2.86818 12.4964C4.18518 10.1923 5.63485 8.54382 7.19335
    7.4867L6.07065 5.83154C4.19115 7.10641 2.55282 9.0179 1.13182 11.5039L2.86818 12.4964Z"
    fill="#161E2F"
  />
  <path
    d="M9.08797 4.40368C8.55698 4.55558 8.24967 5.10916 8.40157 5.64015C8.55346 6.17114 9.10705
    6.47845 9.63803 6.32655L9.08797 4.40368ZM12 5.00012L11.9965 6.00012H12V5.00012ZM22
    12.0001L22.8682 12.4964C23.0439 12.1889 23.0439 11.8114 22.8682 11.504L22 12.0001ZM18.7626
    14.8094C18.3878 15.2149 18.4127 15.8476 18.8182 16.2225C19.2238 16.5973 19.8565 16.5724 20.2314
    16.1669L18.7626 14.8094ZM17.9162 18.1782C18.374 17.8694 18.4949 17.2478 18.1861 16.79C17.8773
    16.3321 17.2557 16.2112 16.7979 16.52L17.9162 18.1782ZM2 12.0001L1.13182 11.5039C0.956079
    11.8113 0.956057 12.1888 1.13177 12.4963L2 12.0001ZM7.19335 7.4867C7.65041 7.17667 7.7696
    6.55483 7.45958 6.09777C7.14956 5.64071 6.52771 5.52151 6.07065 5.83154L7.19335 7.4867ZM9.63803
    6.32655C10.4049 6.10719 11.1989 5.99728 11.9965 6.00011L12.0035 4.00012C11.0175 3.99663 10.036
    4.13249 9.08797 4.40368L9.63803 6.32655ZM12 6.00012C15.52 6.00012 18.5732 8.01902 21.1318
    12.4963L22.8682 11.504C20.0928 6.64721 16.48 4.00012 12 4.00012V6.00012ZM21.1318 11.5038C20.3842
    12.8117 19.5933 13.9106 18.7626 14.8094L20.2314 16.1669C21.1827 15.1376 22.0598 13.9105 22.8682
    12.4964L21.1318 11.5038ZM16.7979 16.52C15.3292 17.5105 13.7394 18.0001 12
    18.0001V20.0001C14.1446 20.0001 16.1228 19.3877 17.9162 18.1782L16.7979 16.52ZM12
    18.0001C8.48001 18.0001 5.4268 15.9812 2.86823 11.504L1.13177 12.4963C3.9072 17.353 7.51999
    20.0001 12 20.0001V18.0001ZM2.86818 12.4964C4.18518 10.1923 5.63485 8.54382 7.19335
    7.4867L6.07065 5.83154C4.19115 7.10641 2.55282 9.0179 1.13182 11.5039L2.86818 12.4964Z"
    fill="white"
    fill-opacity="0.8"
  />
</template>

<script>
export default {
  name: 'VisibilityOffIcon',
};
</script>
