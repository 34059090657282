<template>
  <div class="ip-confirm">
    <div class="ip-confirm__container ip-shadow-m">
      <div class="ip-confirm__title ip-font-title-16-medium">{{ title }}</div>
      <div class="ip-confirm__description">{{ description }}</div>

      <div class="ip-confirm__actions">
        <IPButton
          variant="primary"
          @click="handleReject"
        >
          Отмена
        </IPButton>
        <IPButton
          variant="outline"
          @click="handleConfirm"
        >
          Удалить
        </IPButton>
      </div>
    </div>
  </div>
</template>

<script>
import IPButton from '@/core/components/ui/IPButton.vue';

export default {
  name: 'IPConfirm',

  components: {
    IPButton,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    description: {
      type: String,
      default: '',
    },

    confirmText: {
      type: String,
      default: 'Ок',
    },

    rejectText: {
      type: String,
      default: 'Отмена',
    },

    resolve: {
      type: Function,
    },
  },

  setup(props) {
    const isShown = true;

    function handleConfirm() {
      if (props.resolve) {
        props.resolve(true);
      }
    }

    function handleReject() {
      if (props.resolve) {
        props.resolve(false);
      }
    }

    return {
      handleConfirm,
      handleReject,
      isShown,
    };
  },
};
</script>

<style lang="scss">
.ip-confirm {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  z-index: 900000;

  &__container {
    text-align: center;
    padding: 24px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 20px;

    width: 454px;
    min-height: 169px;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__actions {
    display: flex;
    margin-top: 32px;

    .ip-button-container {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
