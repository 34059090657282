export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "error_code_1001_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войдите в аккаунт"])},
        "error_code_1001_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздел будет доступен после входа в аккаунт"])},
        "error_code_1001_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти ко входу"])},
        "error_code_1002_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните профиль"])},
        "error_code_1002_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздел будет доступен после заполнения профиля"])},
        "error_code_1002_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти в профиль"])},
        "error_code_1003_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ запрещен"])},
        "error_code_1003_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ в раздел запрещен"])},
        "error_code_1003_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На главную"])},
        "error_code_1004_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш отклик отправлен!"])},
        "error_code_1004_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ придет вам на электронную почту, которую вы указали при регистрации"])},
        "error_code_1004_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_1005_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для удаления компании свяжитесь с нами  по адресу info", "@", "clinmarket.ru"])},
        "error_code_1005_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "error_code_1005_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_1006_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация прошла успешно!"])},
        "error_code_1006_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полный функционал будет доступен после подтверждения"])},
        "error_code_1006_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_1007_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено"])},
        "error_code_1007_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Письмо для восстановления пароля отправлено на почту"])},
        "error_code_1007_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_1008_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Такого исследователя в базе ГРЛС на найдено. Проверьте введенные данные"])},
        "error_code_1008_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "error_code_1008_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_2001_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш профиль находится на проверке"])},
        "error_code_2001_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздел будет доступен после проверки вашего профиля"])},
        "error_code_2001_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_2002_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш профиль находится на проверке"])},
        "error_code_2002_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздел будет доступен после проверки вашего профиля"])},
        "error_code_2002_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_2003_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, отправьте ваш профиль на проверку"])},
        "error_code_2003_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздел будет доступен после проверки вашего профиля"])},
        "error_code_2003_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В профиль"])},
        "error_code_2004_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подтвердите email"])},
        "error_code_2004_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздел будет доступен после подтверждения email"])},
        "error_code_2004_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В профиль"])},
        "error_code_3001_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш email подтвержден"])},
        "error_code_3001_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "error_code_3001_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_3002_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код активации устарел"])},
        "error_code_3002_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "error_code_3002_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить новый"])},
        "error_code_3003_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код активации отправлен на почту"])},
        "error_code_3003_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "error_code_3003_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_4001_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение устарело или было удалено"])},
        "error_code_4001_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, обратитесь к своему администратору."])},
        "error_code_4001_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_0_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уже состоите в этой компании"])},
        "error_code_0_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста выберите другую компанию"])},
        "error_code_0_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
        "error_code_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните профиль"])},
        "error_code_1_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздел будет доступен после заполнения профиля"])},
        "error_code_1_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти в профиль"])},
        "error_code_18_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главный исследователь уже зарегистрирован"])},
        "error_code_18_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "error_code_18_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОК"])},
        "error_code_19_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уже откликнулись на данное объявление"])},
        "error_code_19_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "error_code_19_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОК"])},
        "error_code_24_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь уже состоит в компании"])},
        "error_code_24_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "error_code_24_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОК"])},
        "error_code_27_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уже отправили приглашение"])},
        "error_code_27_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, выберите другое объявление"])},
        "error_code_27_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОК"])},
        "error_code_29_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете пригласить данного пользователя, так как он является главным исследователем"])},
        "error_code_29_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "error_code_29_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОК"])},
        "error_code_30_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главный исследователь не может принять приглашение"])},
        "error_code_30_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "error_code_30_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОК"])},
        "error_code_31_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете удалить опрос, так как он привязан к вакансиям"])},
        "error_code_31_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что бы удалить опрос, пожалуйста, поменяйте опросы в следующих вакансиях"])},
        "error_code_31_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОК"])},
        "error_code_14_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнитель не предоставляет услуг, необходимые в данном объявлении"])},
        "error_code_14_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, выберете другое объявление"])},
        "error_code_14_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОК"])},
        "error_code_32_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнитель не предоставляет услуг, необходимые в данном объявлении"])},
        "error_code_32_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, выберете другое объявление"])},
        "error_code_32_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОК"])}
      }
    }
  })
}
