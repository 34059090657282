export default {
  namespaced: true,
  state: {
    info: null,
    token: null,
    permissions: [],
    emailInvite: null,
  },
  mutations: {
    info(state, value) {
      state.info = value;
    },
    token(state, value) {
      state.token = value;
    },
    permissions(state, value) {
      state.permissions = value;
    },
    emailInvite(state, value) {
      state.emailInvite = value;
    },
  },
};
