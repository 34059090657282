<template>
<div
  :class="className"
  v-if="isShow"
  @mousedown.self="handleClose('back')"
  @keyup.esc="handleClose('back')"
>
  <div class="ip-modal__container ip-shadow-m">
    <div class="ip-modal__header">
      <div class="ip-modal__action"><slot name="action"/></div>
      <div v-if="closeable" class="ip-modal__close" @click="handleClose" @keyup.esc="handleClose">
        <IPIcon name="close" />
      </div>
    </div>

    <div class="ip-modal__title ip-font-title-24-medium flex-row flex-row_items-center">
      <div
        v-if="backRouteName"
        class="ip-modal__back"
        @click="handleGoBack"
        @keyup.esc="handleGoBack"
      >
        <IPIcon name="arrow" size="lg" direction="left" />
      </div>
      <slot name="title"/>
    </div>

    <div class="ip-modal__content">
      <IPLoader v-if="isLoading"/>
      <slot />
    </div>
    <div class="ip-modal__footer">
      <slot name="footer" />
    </div>
  </div>
</div>
</template>

<script>
import { computed, watch } from 'vue';

import IPLoader from '@/core/components/ui/IPLoader.vue';
import IPIcon from '@/core/components/ui/IPIcon';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'IPModal',

  emits: ['close'],

  components: {
    IPLoader,
    IPIcon,
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: 'sm',
      validate(value) {
        return ['sm', 'md', 'lg', 'ex'].includes(value);
      },
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    closeable: {
      type: Boolean,
      default: true,
    },

    closeRouterName: {
      type: String,
    },

    backRouteName: {
      type: String,
    },
  },

  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const isShow = computed({
      get() {
        if (props.modelValue) {
          document.body.classList.add('overflow-hidden');
        }

        return props.modelValue;
      },

      set(val) {
        emit('update:modelValue', val);
      },
    });

    watch(() => isShow.value, (value) => {
      if (value === false) {
        document.body.classList.remove('overflow-hidden');
        emit('close');
      }
    });

    const className = computed(() => ['ip-modal', `ip-modal_${props.size}`]);

    const handleClose = (type) => {
      if (props.closeable) {
        if (type === 'back' && !props.closeable) {
          return;
        }
        isShow.value = false;

        router.push({ name: props.closeRouterName || route.name, query: {} });
      }
    };

    const handleGoBack = () => {
      emit('go-back');
      router.push({ name: route.name, query: { m: props.backRouteName } });
    };

    return {
      isShow,
      className,
      handleClose,
      handleGoBack,
    };
  },
};
</script>

<style lang="scss">
.ip-modal {
  background: rgba(55, 55, 55, 0.25);
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  overflow: auto;
  padding: 20px;

  width: 100vw;
  height: 100vh;

  &__scroll-wrapper {
    position: relative;
  }

  &_sm {
    .ip-modal__container {
      width: 320px;
    }
  }

  &_md {
    .ip-modal__container {
      width: 400px;
    }
  }

  &_lg {
    .ip-modal__container {
      width: 600px;
    }
  }

  &_ex {
    .ip-modal__container {
      width: 904px;
    }
  }

  &__container {
    margin-top: 20px;
    padding: 24px;
    box-sizing: border-box;
    background: var(--color-root-base-white);
    border-radius: 20px;
    height: fit-content;
  }

  &__header {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
  }

  &__title {
    color: var(--color-root-black);
    margin-bottom: 24px;
  }

  &__description {
    margin: 16px 0;
  }

  &__close {
    margin-left: auto;
    cursor: pointer;
  }

  &__back {
    cursor: pointer;
  }

  &__content {
    position: relative;
    min-width: 100%;
    box-sizing: border-box;
  }

  &__footer {
    margin-top: 32px;
  }
}
</style>
