export default {
  namespaced: true,

  state: {
    message: null,
  },

  mutations: {
    message(state, value) {
      state.message = value;
    },
  },
};
