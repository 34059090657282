<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0
      23.2843 6.71573 30 15 30Z"
      fill="#5DD7A3"
    />
    <path
      d="M9.42426 13.5757C9.18995 13.3414 8.81005 13.3414 8.57574 13.5757C8.34142 13.8101 8.34142
      14.1899 8.57574 14.4243L9.42426 13.5757ZM13.5 18.5L13.0757 18.9243C13.3047 19.1533 13.6741
      19.1592 13.9104 18.9377L13.5 18.5ZM21.9104 11.4377C22.1521 11.2111 22.1644 10.8314 21.9377
      10.5896C21.7111 10.3479 21.3314 10.3356 21.0896 10.5623L21.9104 11.4377ZM8.57574
      14.4243L13.0757 18.9243L13.9243 18.0757L9.42426 13.5757L8.57574 14.4243ZM13.9104
      18.9377L21.9104 11.4377L21.0896 10.5623L13.0896 18.0623L13.9104 18.9377Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSuccess',
};
</script>
