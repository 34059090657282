<template>
  <path
    d="M27 16L5 5L7.44444 16M27 16L5 27L7.44444 16M27 16H7.44444"
    stroke="#161E2F"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>

<script>
export default {
  name: 'MessageIcon',
};
</script>
