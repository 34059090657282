<template>
  <router-view/>

  <ErrorModal/>

  <IPNotifications/>
</template>

<script>
import IPNotifications from '@/core/plugins/notification/IPNotification.vue';
import ErrorModal from '@/core/components/site/ErrorModal';

export default {
  components: {
    ErrorModal,
    IPNotifications,
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/application";
</style>
