import { createDefaultRouter } from '@/core/services/router';

export default createDefaultRouter([
  {
    path: 'studies',
    name: 'studies',
    component: () => import('@/views/study/StudyPage'),
  },
  {
    path: 'studies/:id',
    name: 'studies-view',
    component: () => import('@/views/study/StudyView'),
  },

  {
    path: 'requests',
    name: 'requests',
    component: () => import('@/views/request/RequestPage'),
    meta: {
      acl: {
        rules: ['kio', 'vendor', 'medical'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },
  {
    path: 'requests/:id',
    name: 'requests-view',
    component: () => import('@/views/request/RequestView'),
    meta: {
      acl: {
        rules: ['kio', 'vendor', 'medical'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },

  {
    path: 'users',
    name: 'users',
    component: () => import('@/views/user/UserPage'),
    meta: {
      acl: {
        rules: ['sponsor', 'kio'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },
  {
    path: 'users/gi/:id',
    name: 'users-gi',
    component: () => import('@/views/user/UserViews/GIView'),
    meta: {
      acl: {
        rules: ['sponsor', 'kio'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },
  {
    path: 'users/vendor/:id',
    name: 'users-vendor',
    component: () => import('@/views/user/UserViews/VendorView'),
    meta: {
      acl: {
        rules: ['sponsor', 'kio'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },
  {
    path: 'users/kio/:id',
    name: 'users-kio',
    component: () => import('@/views/user/UserViews/KioView'),
    meta: {
      acl: {
        rules: ['sponsor', 'kio'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },

  {
    path: 'profile',
    name: 'profile',
    component: () => import('@/views/profile/ProfileView'),
    meta: {
      acl: {
        rules: ['@'],
      },
    },
  },

  {
    path: 'profile/requests',
    name: 'profile-requests',
    component: () => import('@/views/profile/requests/ProfileRequestPage'),
    meta: {
      acl: {
        rules: ['sponsor', 'kio'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },
  {
    path: 'profile/requests/create',
    name: 'profile-requests-create',
    component: () => import('@/views/profile/requests/ProfileRequestModify'),
    meta: {
      acl: {
        rules: ['sponsor', 'kio'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },
  {
    path: 'profile/requests/:id',
    name: 'profile-requests-update',
    component: () => import('@/views/profile/requests/ProfileRequestModify'),
    meta: {
      acl: {
        rules: ['sponsor', 'kio'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },

  {
    path: 'profile/quiz',
    name: 'profile-quiz',
    component: () => import('@/views/profile/quiz/ProfileQuizPage'),
    meta: {
      acl: {
        rules: ['sponsor', 'kio'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },
  {
    path: 'profile/quiz/create',
    name: 'profile-quiz-create',
    component: () => import('@/views/profile/quiz/ProfileQuizModify'),
    meta: {
      acl: {
        rules: ['sponsor', 'kio'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },
  {
    path: 'profile/quiz/:id',
    name: 'profile-quiz-update',
    component: () => import('@/views/profile/quiz/ProfileQuizModify'),
    meta: {
      acl: {
        rules: ['sponsor', 'kio'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },

  {
    path: 'profile/responses',
    name: 'profile-responses',
    component: () => import('@/views/profile/responses/ProfileResponsesPage'),
    meta: {
      acl: {
        rules: ['sponsor', 'kio'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },

  {
    path: 'profile/requests/outgoing',
    name: 'profile-request-outgoing',
    component: () => import('@/views/profile/requestsOutgoing/ProfileRequestOutgoingPage'),
    meta: {
      acl: {
        rules: ['vendor', 'kio', 'medical'],
        profileCompleted: true,
        adminConfirmed: true,
      },
    },
  },
], 'studies');
