<template>
  <path
    d="M12 5L12 19"
    stroke="#161E2F"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12 5L12 19"
    stroke="white"
    stroke-opacity="0.8"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8 15L12 19"
    stroke="#161E2F"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8 15L12 19"
    stroke="white"
    stroke-opacity="0.8"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M16 15L12 19"
    stroke="#161E2F"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M16 15L12 19"
    stroke="white"
    stroke-opacity="0.8"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>

<script>
export default {
  name: 'ArrowStepIcon',
};
</script>
