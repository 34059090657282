import { createVNode, render } from 'vue';
import Component from '@/core/plugins/confirm/IPConfirm.vue';

export default {
  install(app) {
    async function handler(options) {
      let node = null;
      const container = document.createElement('div');

      const response = await new Promise((resolve) => {
        const vm = createVNode(Component, {
          ...options,
          resolve,
        });
        render(vm, container);

        node = container.firstElementChild;
        document.body.appendChild(node);
      });

      document.querySelector('body').removeChild(node);

      return response;
    }

    app.provide('confirm', handler);
  },
};
