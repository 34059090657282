import { THEME_COLORS } from '@/core/helpers/colors';

// Set app`s theme from state value
export function initTheme() {
  document.documentElement.setAttribute('data-theme', 'default');

  Object.entries(THEME_COLORS).forEach(([key, value]) => {
    const colorName = `--color-${key.toLowerCase().replace(/_/g, '-')}`;

    document.documentElement.style.setProperty(colorName, value);
  });
}

export default {
  initTheme,
};
