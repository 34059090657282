<template>
  <svg
    class="direction direction_animated"
    :class="`direction_${direction}`"
    :width="sizeValue"
    :height="sizeValue"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <SearchIcon v-if="name === 'search'"/>
    <ArrowIcon v-else-if="name === 'arrow'"/>
    <ProfileIcon v-else-if="name === 'profile'"/>
    <CloseIcon v-else-if="name === 'close'"/>
    <IconVisibilityOff v-else-if="name === 'visibility-off'"/>
    <VisibilityIcon v-else-if="name === 'visibility'"/>
    <MessageIcon v-else-if="name === 'message'"/>
    <ArrowStepIcon v-else-if="name === 'arrow-step'"/>
  </svg>
</template>

<script>
import { computed } from 'vue';

import SearchIcon from '@/core/ui/icons/SearchIcon';
import ArrowIcon from '@/core/ui/icons/ArrowIcon';
import ProfileIcon from '@/core/ui/icons/ProfileIcon';
import CloseIcon from '@/core/ui/icons/CloseIcon';
import IconVisibilityOff from '@/core/ui/icons/VisibilityOffIcon';
import VisibilityIcon from '@/core/ui/icons/VisibilityIcon';
import MessageIcon from '@/core/ui/icons/MessageIcon';
import ArrowStepIcon from '@/core/ui/icons/ArrowStepIcon';

export default {
  name: 'IPIcon',

  props: {
    name: {
      type: String,
      required: true,
      validate(value) {
        return [
          'search',
          'arrow',
          'profile',
          'close',
          'visibility-off',
          'visibility',
          'message',
          'arrow-step',
        ].includes(value);
      },
    },

    size: {
      type: String,
      default: 'md',
      validate(value) {
        return [
          'sm',
          'md',
          'lg',
        ].includes(value);
      },
    },

    direction: {
      type: String,
      default: 'left',
      validator(value) {
        return [
          'up',
          'down',
          'left',
          'right',
        ].includes(value);
      },
    },
  },

  components: {
    SearchIcon,
    ArrowIcon,
    ProfileIcon,
    CloseIcon,
    IconVisibilityOff,
    VisibilityIcon,
    MessageIcon,
    ArrowStepIcon,
  },

  setup(props) {
    const sizeValue = computed(() => {
      switch (props.size) {
        case 'sm':
          return 16;
        case 'lg':
          return 32;
        case 'md':
        default:
          return 24;
      }
    });

    return {
      sizeValue,
    };
  },
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}
</style>
