import Component, { add } from '@/core/plugins/notification/IPNotification.vue';

export default {
  install(app) {
    app.component('IPNotification', Component);

    async function handler(title, description, type, options) {
      add(title, description, type, options);
    }

    app.provide('notification', handler);
  },
};
