<template>
  <path
    d="M15 6L9 12L15 18"
    stroke="#5B3AEF"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>

<script>
export default {
  name: 'ArrowIcon',
};
</script>
