import { createStore } from 'vuex';

import user from '@/core/store/modules/user';
import application from '@/core/store/modules/application';
import errorHandler from '@/core/store/modules/errorHandler';

export default createStore({
  modules: {
    user,
    application,
    errorHandler,
  },
});
