<template>
  <path
    d="M8.2218 8.22168L23.7782 23.778"
    stroke="#161E2F"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8.2218 23.7783L23.7782 8.22197"
    stroke="#161E2F"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>

<script>
export default {
  name: 'CloseIcon',
};
</script>
