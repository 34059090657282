import { createRouter, createWebHistory } from 'vue-router';
import { can } from '@/core/services/acl';
import { nextTick } from 'vue';

import Store from '@/store';

export function createDefaultRouter(routers, defaultRoute = null) {
  const routes = [
    {
      path: '/',
      name: 'main',
      component: () => import('@/core/views/layouts/MainLayout.vue'),
      children: routers,
      meta: { defaultRoute },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/core/views/NotFound.vue'),
    },
  ];

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
      return { top: 0 };
    },
  });

  router.afterEach(() => {
    nextTick(() => {
      document.title = 'Clinmarket - платформа по поиску клинических исследований и взаимодействию главных исследователей, вендоров, КИО и спонсоров.';
    });
  });

  router.beforeEach(async (to, from, next) => {
    if (to.meta.defaultRoute && to.meta.defaultRoute !== to.name && to.path === '/') {
      next({ name: to.meta.defaultRoute });
    }

    if (await can(to.meta.acl || null)) {
      next();
    } else if (typeof from.name === 'undefined') {
      Store.commit('application/backRoute', to);
      next({ name: to.meta.redirect || defaultRoute });
    } else {
      Store.commit('application/backRoute', to);
      next({ name: from.name });
    }
  });

  return router;
}

export default createDefaultRouter;
